import React from "react"
import img1 from "../../../static/blogs/1.jpg"
import img2 from "../../../static/blogs/2.jpg"
import img3 from "../../../static/blogs/3.jpg"
import Layout from "../../components/Layout"
import { Link } from "gatsby"

const artists = () => {
  return (
      <Layout>
        <main className={"artists-image w-100 flex-center-rw"}>
          <h1>ARTISTS</h1>
        </main>
        <section className={"artists-content w-100 flex-center-rw"}>
            <Link to={"/blog/where-it-all-begins"}>
              <img src={img1} alt="aykunna uwu" />
              <div>
                <h3>Where It All Begins</h3>
              </div>
            </Link>
            <Link to={"/blog/uwu2"}>
              <img src={img2} alt="aykunna uwu" />
              <div>
                <h3>Smiles And Adventure</h3>
              </div>
            </Link>
            <Link to={"/blog/uwu3"}>
              <img src={img3} alt="aykunna uwu" />
              <div>
                <h3>Landscapes Of Joy</h3>
              </div>
            </Link>
        </section>
      </Layout>
  )
}

export default artists;